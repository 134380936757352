import { createReducer, on } from '@ngrx/store';
import { ActionWithPayload } from '.';
import * as notificationAction from '../actions/notification';
import { NotificationMessage } from '../../models/notification';

export interface State {
    messages: NotificationMessage[];
};

export const initialState: State = {
    messages: []
};

const featureReducer = createReducer(
    initialState,
    on(notificationAction.DisplayNotification, (state, action) => {
        if ( !action.payload.message || action.payload.message == "" ) return state;

        state.messages.forEach((message: NotificationMessage) => {
            if ( message.message == action.payload.message ) return state;
            return null;
        })

        return {
            messages: [ ...state.messages, action.payload ]
        };
    }),
    on(notificationAction.ShownNotification, (state) => {
        let messages = [ ...state.messages ];

        if ( messages.length > 0 ) messages.shift();

        return { messages };
    })
);

export function reducer(state:State = initialState, action: ActionWithPayload) {
    return featureReducer(state, action);
}

export const getMessage = (state: State) => state.messages;