import { createReducer, on } from '@ngrx/store';
import { ActionWithPayload } from '.';
import * as loadingAction from '../actions/loading';

export interface State {
    loading: boolean;
};

export const initialState: State = {
    loading: false
};

const featureReducer = createReducer(
    initialState,
    on(loadingAction.ShowLoading, (state) => state = { loading: true }),
    on(loadingAction.HideLoading, (state) => state = { loading: false })
);

export function reducer(state:State = initialState, action: ActionWithPayload) {
    return featureReducer(state, action);
}

export const getLoading = (state: State) => state.loading;