export interface State {
    idx: number;
    Title?: string;
    FirstName: string;
    MiddleName?: string;
    LastName: string;
    MobilePhone?: string;
    EMail?: string;
    DateAdded?: string;
    DOB?: string;
    FormRequested: boolean;
    updatedJWT?: string;
    NewPatient?: boolean;
    sms?: boolean;
    emailshot?: boolean;
    lastAppointment?: any;
}

export const initialState: State = {
    idx: 0,
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    MobilePhone: "",
    EMail: "",
    DateAdded: "",
    DOB: "",
    FormRequested: false,
    NewPatient: false
}