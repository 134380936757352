import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { MaterialModule } from "src/app/material/material.module";
import { AppointmentInfoComponent } from "src/app/components/appointment-info/appointment-info.component";
import { IcalDownloadComponent } from "../pages/ical-download/ical-download.component";
import { StaffMemberModule } from "./staff-member/staff-member.module";


@NgModule({
    imports: [
      CommonModule,
      FormsModule,
      MaterialModule,
      StaffMemberModule
    ],
    declarations: [
        IcalDownloadComponent,
        AppointmentInfoComponent
    ],
    providers: [],
    exports:[
        IcalDownloadComponent,
        AppointmentInfoComponent
    ]
})
export class PostBookingInfoModule { }

