import { createAction, props } from '@ngrx/store';
import { NotificationMessage } from 'src/app/models/notification';

export const DISPLAY = 'NOTIFICATION_DISPLAY';
export const SHOWN = 'NOTIFICATION_SHOWN';

export const ActionTypes = {
  DISPLAY: '[NotificationState] Display',
  SHOWN: '[NotificationState] Shown'
};

export const DisplayNotification = createAction(ActionTypes.DISPLAY, props<{payload: NotificationMessage }>());
export const ShownNotification = createAction(ActionTypes.SHOWN);