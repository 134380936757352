import { NgModule } from '@angular/core';
import { MaterialModule } from 'src/app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StaffMemberComponent } from './staff-member.component';


@NgModule({ declarations: [
        StaffMemberComponent
    ],
    exports: [
        StaffMemberComponent
    ], imports: [
        MaterialModule,
        ReactiveFormsModule,
        FormsModule,
        CommonModule
    ]
    })
export class StaffMemberModule { }

