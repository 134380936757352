<div class="staffmember">
    <div *ngIf="selectedStaff?.photo" class="image" [style.background-image]="'url('+selectedStaff?.photo+')'">
    </div>
    <div *ngIf="!selectedStaff?.photo" class="image">
      <mat-icon class="material-symbols-outlined person-icon">
        person
      </mat-icon>
    </div>
        <div class="name mt-3">
          <strong>{{ selectedStaff?.firstname }} {{ selectedStaff?.lastname }}</strong>
        </div>
        <div class="reg">
            {{ selectedStaff?.profReg ? selectedStaff?.profReg : "" }}
        </div>
</div>
