<div class="row">
    <div class="col-sm-4">
        <app-staff-member [selectedStaff]="staff"></app-staff-member>
    </div>
    <div class="col-sm-8 flex-on-desktop" *ngIf="appointment">
        <div class="d-flex my-1">
                <mat-icon>health_and_safety</mat-icon>
                <div class="me-3 w-80">
                    Treatment
                </div>
                <div>
                    {{ appointment.treatmentType }}
                </div>
            
        </div>

        <div class="d-flex my-1">
            <mat-icon>credit_card</mat-icon>
            <div class="me-3 w-80">Price</div>
            <div>
                {{ appointment.price | currency : 'GBP'}}
            </div>
        </div>

        <div class="d-flex my-1">
            <mat-icon>event</mat-icon>
            <div class="me-3 w-80">
                Time
            </div>
            <div>
                {{ appointment.startTime | date : "EEEE, MMMM d, y h:mm a" }}
            </div>
        </div>

        <div class="d-flex my-1">
            <mat-icon>alarm</mat-icon>
            <div class="me-3 w-80">
                Duration
            </div>
            <div>
                {{ appointment.duration }} minutes
            </div>
        </div>
    </div>
</div>