import { createAction, props } from '@ngrx/store';
import { SiteLinks } from 'src/app/models/config';

export const INIT = 'CONFIG_INIT';
export const LOAD = 'CONFIG_LOAD';
export const SET = 'CONFIG_SET';

export const ActionTypes = {
  INIT: '[ConfigState] Init',
  LOAD: '[ConfigState] Load',
  SET: '[ConfigState] Set',
  SET_JWT: '[ConfigState] Set JWT',
  SET_CLINIC_NAME: '[ConfigState] Set Clinic Name',
  SET_LINKS: '[ConfigState] Set Links'
};

export const InitializeAction = createAction(ActionTypes.INIT);
export const LoadConfig = createAction(ActionTypes.LOAD, props<{ payload: any }>());
export const SetConfig = createAction(ActionTypes.SET, props<{ payload: any }>());
export const SetJWT = createAction(ActionTypes.SET_JWT, props<{ payload: string }>());
export const SetClinicName = createAction(ActionTypes.SET_CLINIC_NAME, props<{ payload: string }>());
export const SetLinks = createAction(ActionTypes.SET_LINKS, props<{ payload: SiteLinks }>());

