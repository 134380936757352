import { createAction, props } from '@ngrx/store';

export const INIT = 'APPOINTMENT_INIT';
export const SET_APPOINTMENT = 'APPOINTMENT_SET_APPOINTMENT';

export const ActionTypes = {
  INIT: '[AppointmentState] Init',
  SET_APPOINTMENT: '[AppointmentState] Set appointment',
};

export const InitializeAction = createAction(ActionTypes.INIT);
export const SetAppointment = createAction(ActionTypes.SET_APPOINTMENT, props<{payload: any }>());