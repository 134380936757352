import { Action, createReducer, on } from '@ngrx/store';
import { setSelectedDate, clearSelectedDate } from '../actions/date';

export interface State {
    selectedDate: any| null;
  }
  
  export const initialState: State = {
    selectedDate: null,
  };
  // sets the selected date
  const dateReducer = createReducer(
    initialState,
    on(setSelectedDate, (state, { selectedDate }) => ({
      ...state,
      selectedDate,
    })),
    on(clearSelectedDate, (state) => ({
      ...state,
      selectedDate: null,
    }))
  );
  
  export function reducer(state: State | undefined, action: Action) {
    return dateReducer(state, action);
  }

  export const getDate = (state: State) => state.selectedDate