import { createReducer, on } from '@ngrx/store';
import { ActionWithPayload } from '.';
import * as configAction from '../actions/config';
import { State, initialState } from '../../models/config';

export { State, initialState };

const featureReducer = createReducer(
    initialState,
    on(configAction.InitializeAction, (state) => state = initialState),
    on(configAction.SetConfig, (state, action) => state = {...state,  clinic: action.payload.clinic, settings: action.payload.settings }),
    on(configAction.SetJWT, (state, action) => state = { ...state, jwt: action.payload }),
    on(configAction.SetClinicName, (state, action) => state = { ...state, clinicName: action.payload }),
    on(configAction.SetLinks, (state, action) => state = {...state, links: action.payload})
);

export function reducer(state:State = initialState, action: ActionWithPayload) {
    return featureReducer(state, action);
}

export const getJWT = (state: State) => state.jwt;
export const getClinicDetails = (state: State) => state.clinic;
export const getClinicName = (state: State) => state.clinicName;
export const getApprovalRequired = (state: State) => state.links?.approvalRequired;
export const getAvailabilities = (state: State) => state?.settings?.availability;
export const getDiarySettings = (state: State) => {
    return {
        startHour: state?.settings?.startHour || 9,
        endHour: state?.settings?.endHour || 17,
        increment: state?.settings?.increment || 15
    }
}
export const getSiteConfig = (state: State) => state.links;

