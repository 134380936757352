import { createReducer, on } from '@ngrx/store';
import { ActionWithPayload } from '.';
import * as bookingAction from '../actions/appointment';
import { State, initialState } from '../../models/appointment';

export { State, initialState };

const featureReducer = createReducer(
    initialState,
    on(bookingAction.InitializeAction, (state) => state = initialState),
    on(bookingAction.SetAppointment, (state, action) => state = action.payload)
);

export function reducer(state:State = initialState, action: ActionWithPayload) {
    return featureReducer(state, action);
}

export const getAppointment = (state: State) => state;