import { createAction, props } from '@ngrx/store';
import { State } from '../reducers/patient';

export const INIT = 'PATIENT_INIT';
export const SET_PATIENT = 'PATIENT_SET_PATIENT';
export const SET_FORM_REQUESTED = 'PATIENT_SET_FORM_REQUESTED';
export const CLEAR_STORE = 'CLEAR_STORE';

export const ActionTypes = {
  INIT: '[PatientState] Init',
  SET_PATIENT: '[PatientState] Set patient',
  SET_FORM_REQUESTED: '[PatientState] Set form requested',
  CLEAR_STORE: '[StoreState] Clear store'
};

export const InitializeAction = createAction(ActionTypes.INIT);
export const ClearStore = createAction(ActionTypes.CLEAR_STORE);
export const SetPatient = createAction(ActionTypes.SET_PATIENT, props<{payload: State }>());
export const SetFormRequested = createAction(ActionTypes.SET_FORM_REQUESTED, props<{payload: boolean }>());