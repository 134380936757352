import { createAction } from '@ngrx/store';

export const SHOW = 'LOADING_SHOW';
export const HIDE = 'LOADING_HIDE';

export const ActionTypes = {
  SHOW: '[LoadingState] Show',
  HIDE: '[LoadingState] Hide'
};

export const ShowLoading = createAction(ActionTypes.SHOW);
export const HideLoading = createAction(ActionTypes.HIDE);