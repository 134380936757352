import { Injectable } from '@angular/core';
import {  Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DataService } from './data.service';
import { Staff } from '../models/staff';
import { Treatment } from '../models/treatment';


@Injectable({
    providedIn: 'root'
})
export class StaffService {

    private _allStaff: Staff[];

    get allStaff(): Observable<Staff[]>{
        if (this._allStaff){    
            return of(this._allStaff);
        }
        return this.init();
    }

    constructor(private http: DataService) {
       // this.getFromServer().subscribe(res => {
       //     this._allStaff = res;
       // })

    }

    /*
     returns only staff that provide one of the treatments specified in the array
    */
    public onlyStaffWithTreatments(treatments: Treatment[]){
        return this.allStaff.pipe(map(staff => {
            return (staff.filter(s => {
                for(let t of treatments){
                    if (s.availableTreatments.includes(t.idx)){
                        return true;
                    }
                }
                return false;
            }))
        }))
    }

    private init(): Observable<Staff[]>{
        return this.getFromServer().pipe(map(res => {
            this._allStaff = res;
            return res;
        }));
    }

    
    public staffFromIdx(idx: number) : Observable<Staff | undefined>{
        return this.allStaff.pipe(map(staff => {
            return staff.find((f => (f.idx === idx)));
        }));
    }

    public getFromServer(): Observable<any> {
        return this.http.get('/public/staff');
    }


}
