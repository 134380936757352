"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = setLocation;
var _formatText = _interopRequireDefault(require("./format-text"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function setLocation(location) {
  return (0, _formatText["default"])(location);
}