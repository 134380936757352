import { Theme } from "../services/theme.service";

export interface SiteConfig{
    availability: any,
    startHour: number,
    endHour: number,
    increment: number
}

export interface SiteLinks{
    privacyPolicy: string,
    cancellationPolicy: string,
    contactUs: string,
    organisationName: string,
    theme: Theme,
    approvalRequired: boolean
}


export interface State {
    jwt: string;
    clinic?: any;
    links?: SiteLinks
    clinicName?: string;
    settings?: SiteConfig | undefined
}

export const initialState: State = {
    jwt: "",
    clinic: null,
    clinicName: ""
}
