import { createAction, props } from '@ngrx/store';
import { Treatment } from 'src/app/models/treatment';
import { AppointmentRequest } from 'src/app/models/booking';
import { Staff } from 'src/app/models/staff';

export const INIT = 'BOOKING_INIT';
export const SET_STAFF = 'BOOKING_SET_STAFF';
export const SET_TREATMENT = 'BOOKING_SET_TREATMENT';
export const CLEAR_TIMES = 'BOOKING_CLEAR_TIMES';
export const GET_STAFF_MEMBER = 'BOOKING_GET_STAFF';
export const REQUEST_APPOINTMENT = 'REQUEST_APPOINTMENT';

export const ActionTypes = {
  INIT: '[BookingState] Init',
  SET_STAFF: '[BookingState] Set staff',
  SET_TREATMENT: '[BookingState] Set treatment details',
  CLEAR_TIMES: '[BookingState] Clear times',
  SET_TREATMENT_AND_STAFF: '[BookingState] Treat and Staff',
  REQUEST_APPOINTMENT: '[BookingState] Request Appointment',
  SET_IDX: '[BookingState] Set Idx'
};

export const InitializeAction = createAction(ActionTypes.INIT);
export const SetStaffMember = createAction(ActionTypes.SET_STAFF, props<{payload: number[] }>());
export const SetTreatmentDetails = createAction(ActionTypes.SET_TREATMENT, props<{payload: Treatment }>());
export const ClearBookingTimes = createAction(ActionTypes.CLEAR_TIMES);
export const SetTreatmentAndStaff = createAction(ActionTypes.SET_TREATMENT_AND_STAFF, props<{payload: {staff: number[], treatmentTypeIdx: number, treatmentIdx: number}}>());
export const RequestAppointment = createAction(ActionTypes.REQUEST_APPOINTMENT, props<{payload: AppointmentRequest }>());
export const SetAppointmentIdx = createAction(ActionTypes.SET_IDX, props<{payload: number}>());
