import { ActionReducer, ActionReducerMap, Action, MetaReducer } from '@ngrx/store';
import { localStorageSync, LocalStorageConfig } from 'ngrx-store-localstorage';
import { createSelector } from 'reselect';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../../environments/environment';
import { ActionTypes as PatientActions } from "../actions/patient";

import * as fromBooking from './booking';
import * as fromPatient from './patient';
import * as fromNotification from './notification';
import * as fromApppointment from './appointment';
import * as fromLoading from './loading';
import * as fromConfig from './config';
import * as fromDate from './date';


export interface State {
    booking: fromBooking.State;
    patient: fromPatient.State;
    notification: fromNotification.State;
    appointment: fromApppointment.State;
    loading: fromLoading.State;
    config: fromConfig.State;
    date: fromDate.State
}

export interface ActionWithPayload extends Action {
    payload?: any;
}

export const reducers: ActionReducerMap<State> = {
    booking: fromBooking.reducer,
    patient: fromPatient.reducer,
    notification: fromNotification.reducer,
    appointment: fromApppointment.reducer,
    loading: fromLoading.reducer,
    config: fromConfig.reducer,
    date: fromDate.reducer
};

export const initialState: State = {
    booking: fromBooking.initialState,
    patient: fromPatient.initialState,
    notification: fromNotification.initialState,
    appointment: fromApppointment.initialState,
    loading: fromLoading.initialState,
    config: fromConfig.initialState,
    date: fromDate.initialState
}

export const localStorageConfig: LocalStorageConfig = { 
    keys: ['booking', 'patient', 'appointment', 'config', 'date'],
    rehydrate: true 
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(localStorageConfig)(reducer);
}

export function clearState(reducer: any) {
    return function (state: State, action: ActionWithPayload) {
      switch ( action.type ) {
        case PatientActions.CLEAR_STORE: return reducer({ ...initialState, config: state.config }, action);
        default: return reducer(state, action);
      }
    };
}

export const developmentMetaReducers: MetaReducer<State>[] = [localStorageSyncReducer, storeFreeze];
export const productionMetaReducers: MetaReducer<State>[] = [localStorageSyncReducer];
export const metaReducers: MetaReducer<State>[] = !environment.production ? developmentMetaReducers : productionMetaReducers;

export const getBookingState = (state: State) => state.booking;
export const getPatientState = (state: State) => state.patient;
export const getNotificationState = (state: State) => state.notification;
export const getAppointmentState = (state: State) => state.appointment;
export const getLoadingState = (state: State) => state.loading;
export const getConfigState = (state: State) => state.config;
export const getDateState = (state: State) => state.date;
export const clearDateState = (state: State) => state.date;

export const getSelectedStaff = createSelector(getBookingState, fromBooking.getStaff);
export const getBookingDetails = createSelector(getBookingState, fromBooking.getTreatmentDetails);

export const isPatientLoggedIn = createSelector(getPatientState, fromPatient.isLoggedIn);
export const getPatientDetails = createSelector(getPatientState, fromPatient.getPatient);

export const getNotificationMessages = createSelector(getNotificationState, fromNotification.getMessage);

export const getCurrentAppointment = createSelector(getAppointmentState, fromApppointment.getAppointment);

export const getLoading = createSelector(getLoadingState, fromLoading.getLoading);
export const getDate = createSelector(getDateState, fromDate.getDate);

export const getJWT = createSelector(getConfigState, fromConfig.getJWT);
export const getClinicDetails = createSelector(getConfigState, fromConfig.getClinicDetails);
export const getClinicName = createSelector(getConfigState, fromConfig.getClinicName);
export const getDiarySettings = createSelector(getConfigState, fromConfig.getDiarySettings);
export const getAvailabilities = createSelector(getConfigState, fromConfig.getAvailabilities);
export const getSiteConfig = createSelector(getConfigState, fromConfig.getSiteConfig);
export const getApprovalRequired = createSelector(getConfigState, fromConfig.getApprovalRequired);
export const getTreatmentAndStaff = createSelector(getBookingState, fromBooking.getTreatmentAndStaff);
export const getRequestedAppointment = createSelector(getBookingState, fromBooking.getRequestedAppointment);
