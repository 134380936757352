import { Injectable } from '@angular/core';
import * as loadingActions from '../store/actions/loading';
import * as fromRoot from '../store/reducers';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  constructor( private store: Store<fromRoot.State> ) { }

  public start(): void {
    this.store.dispatch(loadingActions.ShowLoading());
  }

  public stop(): void {
    this.store.dispatch(loadingActions.HideLoading());
  }
}
