"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = setAlarm;
var _formatDate = _interopRequireDefault(require("./format-date"));
var _foldLine = _interopRequireDefault(require("./fold-line"));
var _encodeNewLines = _interopRequireDefault(require("./encode-new-lines"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function setDuration(_ref) {
  var weeks = _ref.weeks,
    days = _ref.days,
    hours = _ref.hours,
    minutes = _ref.minutes,
    seconds = _ref.seconds;
  var formattedString = 'P';
  formattedString += weeks ? "".concat(weeks, "W") : '';
  formattedString += days ? "".concat(days, "D") : '';
  formattedString += 'T';
  formattedString += hours ? "".concat(hours, "H") : '';
  formattedString += minutes ? "".concat(minutes, "M") : '';
  formattedString += seconds ? "".concat(seconds, "S") : '';
  return formattedString;
}
function setTrigger(trigger) {
  var formattedString = '';
  if (Array.isArray(trigger) || typeof trigger === 'number' || typeof trigger === 'string') {
    formattedString = "TRIGGER;VALUE=DATE-TIME:".concat((0, _encodeNewLines["default"])((0, _formatDate["default"])(trigger)), "\r\n");
  } else {
    var alert = trigger.before ? '-' : '';
    formattedString = "TRIGGER:".concat((0, _encodeNewLines["default"])(alert + setDuration(trigger)), "\r\n");
  }
  return formattedString;
}
function setAction(action) {
  return action.toUpperCase();
}
function setAlarm() {
  var attributes = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = attributes.action,
    repeat = attributes.repeat,
    description = attributes.description,
    duration = attributes.duration,
    attach = attributes.attach,
    attachType = attributes.attachType,
    trigger = attributes.trigger,
    summary = attributes.summary;
  var formattedString = 'BEGIN:VALARM\r\n';
  formattedString += (0, _foldLine["default"])("ACTION:".concat((0, _encodeNewLines["default"])(setAction(action)))) + '\r\n';
  formattedString += repeat ? (0, _foldLine["default"])("REPEAT:".concat(repeat)) + '\r\n' : '';
  formattedString += description ? (0, _foldLine["default"])("DESCRIPTION:".concat((0, _encodeNewLines["default"])(description))) + '\r\n' : '';
  formattedString += duration ? (0, _foldLine["default"])("DURATION:".concat(setDuration(duration))) + '\r\n' : '';
  var attachInfo = attachType ? attachType : 'FMTTYPE=audio/basic';
  formattedString += attach ? (0, _foldLine["default"])((0, _encodeNewLines["default"])("ATTACH;".concat(attachInfo, ":").concat(attach))) + '\r\n' : '';
  formattedString += trigger ? setTrigger(trigger) : '';
  formattedString += summary ? (0, _foldLine["default"])("SUMMARY:".concat((0, _encodeNewLines["default"])(summary))) + '\r\n' : '';
  formattedString += 'END:VALARM\r\n';
  return formattedString;
}

// Example:  A duration of 15 days, 5 hours, and 20 seconds would be:

// P15DT5H0M20S

// A duration of 7 weeks would be:

// P7W