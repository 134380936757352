import { createReducer, on } from '@ngrx/store';
import { ActionWithPayload } from '.';
import * as patientAction from '../actions/patient';
import { State, initialState } from '../../models/patient';

export { State, initialState };

const featureReducer = createReducer(
    initialState,
    on(patientAction.InitializeAction, (state) => state = initialState),
    on(patientAction.SetPatient, (state, action) => state = action.payload),
    on(patientAction.SetFormRequested, (state, action) => state = { ...state, FormRequested: action.payload }),
    on(patientAction.ClearStore, (state) => state = initialState)
);

export function reducer(state:State = initialState, action: ActionWithPayload) {
    return featureReducer(state, action);
}

export const isLoggedIn = (state: State) => (( state.FirstName && state.FirstName !== "" ) || ( state.LastName && state.LastName !== "" )) ? true : false ;
export const getPatient = (state: State) => state;