import { Component, HostListener, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormControl, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from "@ngrx/store";
import { initialState, State } from 'src/app/models/patient';

import { NotificationService } from 'src/app/services/notification.service';
import * as fromRoot from '../../store/reducers';
import * as PatientActions from '../../store/actions/patient';

import { AppointmentService } from 'src/app/services/appointment.service';

import { ConfigService } from 'src/app/services/config.service';
import { LoadingService } from 'src/app/services/loading.service';
import { Location } from '@angular/common';
import { AppointmentRequest } from 'src/app/models/booking';
import { StaffService } from 'src/app/services/staff.service';
import { take } from 'rxjs';



@Component({
    selector: 'app-appointment-info',
    templateUrl: './appointment-info.component.html',
    styleUrls: ['./appointment-info.component.scss'],
    standalone: false
})

export class AppointmentInfoComponent implements OnInit {

    @Input() appointment;

    public loggedIn: boolean = false;
    public patient: State = initialState;
    public staff: any;
    public activeAppointment: any;
    public clinic: any;
    public emailClinic: string;
    public appointmentSaved = false;

    public requestedAppointment: AppointmentRequest | undefined;

    constructor(private store: Store<fromRoot.State>,
        private config: ConfigService,
        private staffService: StaffService,
    ) {




        this.config.getStoredClinicDetails().subscribe((clinic: any) => this.clinic = clinic);
    }


    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges) {
        if (this.appointment) {
            this.staffService.staffFromIdx(this.appointment.staffIdx).pipe(take(1)).subscribe(res => {
                console.log("STAFF=", res);
                this.staff = res;
            });
        }

    }
}