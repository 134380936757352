import { createReducer, on } from '@ngrx/store';
import { ActionWithPayload } from '.';
import * as bookingAction from '../actions/booking';
import { State, initialState } from '../../models/booking';

export { State, initialState };

const featureReducer = createReducer(
    initialState,
    on(bookingAction.InitializeAction, (state) => state = initialState),
    on(bookingAction.ClearBookingTimes, (state) => {
        return {
            ...state,
            startTime: null,
            endTime: null
        }
    }),
    on(bookingAction.SetStaffMember, (state, action) => {
        return {
            ...state,
            staff: action.payload
        }
    }),
    on(bookingAction.SetTreatmentDetails, (state, action) => {
        return {
            ...state,
            treatment: action.payload
        }
    }),
    on(bookingAction.SetTreatmentAndStaff, (state, action) => {
        return {
            ...state,
            ...action.payload
        }
    }),
    on(bookingAction.RequestAppointment, (state, action) => {
        return {
            ...state,
            requested: action.payload
        }
    }),
    on(bookingAction.SetAppointmentIdx, (state, action) => {
        return {
            ...state, 
            requested: {...state.requested, idx: action.payload}
        }
    })
);

export function reducer(state:State = initialState, action: ActionWithPayload) {
    return featureReducer(state, action);
}

export const getTreatmentAndStaff = (state: State) => {
    return {
        treatmentTypeIdx: state.treatmentTypeIdx,
        treatment: state.treatment, 
        staff: state.staff
    }
}


export const getStaff = (state: State) => state.staff;
export const getTreatmentDetails = (state: State) => {
    return {
        treatmentCategory: state.treatmentCategory,
        treatmentIdx: state.treatment,
        startTime: state.startTime,
        endTime: state.endTime,
        duration: state.duration,
        price: state.price
    }
}

export const getRequestedAppointment = (state: State) => {
    return state.requested;
}