import { Treatment } from "./treatment";

export interface AppointmentRequest {
    idx?: number
    treatmentType?: string,
    treatmentIdx?: number,
    duration: number,
    price?: string,
    startTime: string,
    staffIdx: number
}


export interface State {
    staff: number[];
    treatmentCategory: string;
    treatmentTypeIdx: number;
    treatment?: Treatment;
    startTime?: any;
    endTime?: any;
    duration?: any;
    price?: string;
    requested: AppointmentRequest;
}

export const initialState:State = {
    staff: [],
    treatmentCategory: '',
    treatmentTypeIdx: 0,
    requested: { idx: 0, startTime: '', duration: 0, staffIdx: 0}
}
