export interface State {
    idx: number;
    StartTime?: Date;
    Duration: number;   
    Status: string;
    BookingMadeAt?: Date;
}

export const initialState = {
    idx: 0,
    Duration: 0,   
    Status: "",
}
