import { Component, HostListener, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import * as ics from 'ics';
import { switchMap, take } from 'rxjs/operators';
import { AppointmentRequest } from 'src/app/models/booking';
import { Staff } from 'src/app/models/staff';
import { ConfigService } from 'src/app/services/config.service';
import { StaffService } from 'src/app/services/staff.service';

@Component({
    selector: 'app-ical-download',
    templateUrl: './ical-download.component.html',
    styleUrls: ['./ical-download.component.scss'],
    standalone: false
})
export class IcalDownloadComponent implements OnInit {

  public eventString;
  public clinicDetails;
  public clinicDetails$;
  public staff: Staff | undefined;
  @Input() appointment: any;

  readonly CLINICNAME: string = "clinicName";

  constructor(
    private configService: ConfigService, 
    private staffService: StaffService,
    private route: ActivatedRoute) { }
  ngOnInit() {
    
    this.clinicDetails$ = this.configService.getStoredClinicDetails().subscribe(res=>{
      this.clinicDetails = res;
    });

  }

  ngOnDestroy(){
    this.clinicDetails$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges){    
    if (!this.appointment){
      return;
    }
    console.log("get staff from id ",this.appointment.staffIdx);
    this.staffService.staffFromIdx(this.appointment.staffIdx).subscribe(res=>{
      this.staff = res;
    });
  }

  makeEvent(): ics.EventAttributes {
    const dateTimeParts = this.appointment.startTime.split(" ");
    const dateParts = dateTimeParts[0].split("-");
    const timeParts = dateTimeParts[1].split(":");

    console.log(this.clinicDetails);
    const start: ics.DateArray = [parseInt(dateParts[0]),
        parseInt(dateParts[1]),
        parseInt(dateParts[2]),
        parseInt(timeParts[0]),
        parseInt(timeParts[1])];
    
    return {
      start: start,
      duration: { hours: 0, minutes: this.appointment.duration },
      title: this.appointment.description,
      description: `Your appointment for a ${this.appointment.description} with ${this.staff?.firstname} ${this.staff?.lastname}`,
      location: `${this.clinicDetails.name}, ${this.clinicDetails.address1}, ${this.clinicDetails.postcode}`,
      url: this.clinicDetails.website,
      categories: ['Appointment'],
      status: 'CONFIRMED',
      busyStatus: 'BUSY',
      organizer: { name: this.clinicDetails.name, email: this.clinicDetails.email }
    }

    
  }

  downloadAppointment(){
    ics.createEvent(this.makeEvent(), (error, value) => {
      console.log("create event ", value, error);
      if (error) {
        console.log(error)
        return
      }

      this.downloadICS(value);
    });
  }

  downloadICS(value) {
    console.log("Download");
    const filename = "appointment.ics";
    this.eventString = value;

    const blob = new File([value], filename, { type: 'text/calendar' });
    const url = window.URL.createObjectURL(blob);
    console.log(blob);

  // trying to assign the file URL to a window could cause cross-site
  // issues so this is a workaround using HTML5
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = filename;

  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);

  URL.revokeObjectURL(url);


  }



}
